import React, { ReactNode, useState, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "./logo_color.png";
import IconExpand from "@/icons/IconExpand";
import IconCollapse from "@/icons/IconCollapse";
import useMenuItems from "@/hooks/useMenuItems";
import { MenuItem } from "@/types";
import MainLink from './MainLink';
import ProfileButton from "./ProfileButton";
import ProfileContext from "@velaro/velaro-shared/src/context/ProfileContext";
import StatusContext from "@velaro/velaro-shared/src/context/StatusContext";
import ExpandableMenuLink from "./ExpandableMenuLink";
import LogoWhite from "@velaro/velaro-shared/src/images/Logo_White.svg";
const messagingClientUrl = import.meta.env.VITE_MESSAGING_CLIENT_URL as string;
import AgentWorkspace from "@velaro/velaro-shared/src/svg/stroke-icons/link-external-01.svg?react";

interface Props {
  children: ReactNode;
}

const styles = {
  subLink: "ml-5 p-2 hover:bg-cornflower-blue-400 block rounded-lg transition duration-200 delay-100 hover:delay-100",
  subLinkActive: "ml-5 p-2 rounded-lg bg-cornflower-blue-700 block transition duration-200 delay-100 hover:delay-100",
};

function SubLink({ to, label, onVisit }: { to: string; label: string; onVisit(): void; }) {
  return (
    
      <NavLink
        to={to}
        end
        className={({ isActive }) => (isActive ? styles.subLinkActive : styles.subLink)}
        onClick={onVisit}
      >
        <span className="w-full text-left" style={{ fontSize: 14 }}>{label}</span>
      </NavLink>
    // </div>
  );
}

export default function MainLayout(props: Props) {
  const [leftNavExpanded, setLeftNavExpanded] = useState(true);
  const [leftNavPinned, setLeftNavPinned] = useState(true);
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);
  const [visitedMenu, setVisitedMenu] = useState<string>("");
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const location = useLocation();
  const menuItems = useMenuItems();
  
  const { profile } = useContext(ProfileContext);
  const {status} = useContext(StatusContext);

  const availabilityColor =
    status === "Available"
      ? "bg-green-500"
      : status === "Unavailable"
      ? "bg-sweet-orange-500"
      : "bg-slate-500";

  const sidebarClass = toggleSidebar ? "w-64" : "w-14";
  const toggleSidebarIcon = () => setToggleSidebar(prev => !prev);

  const expandMenu = (menuId: string) => {
    setExpandedMenus(prev => {
      const index = prev.indexOf(menuId);
      return index > -1 ? prev.filter(id => id !== menuId) : [...prev, menuId];
    });
  };

  const renderMenu = (item: MenuItem, index: number) => {
    if (!item.enabled) return null;

    const onVisit = () => {
      setVisitedMenu(item.label);
      if (!leftNavPinned) setLeftNavExpanded(false);
    };

    if (!item.sublinks) {
      return (
        <MainLink 
          label={item.label} 
          to={item.to} 
          icon={item.icon} 
          onVisit={onVisit}
          showTooltip={toggleSidebar}
          toggleSidebar={toggleSidebar}
        />
      );
    }

    return (
      <ExpandableMenuLink
        key={index}
        onExpand={() => expandMenu(item.label)}
        label={toggleSidebar ? item.label : ""}
        icon={item.icon}
        expanded={expandedMenus.includes(item.label)}
        visited={visitedMenu === item.label}
        collapsed={!leftNavExpanded}
        toggleSidebar={toggleSidebar}
      >
       {leftNavExpanded &&
      item.sublinks.map((sublink) =>
        sublink.enabled ? (
          !toggleSidebar ? (
            <SubLink 
              key={sublink.to} 
              to={sublink.to} 
              label={sublink.label} 
              onVisit={onVisit} 
            />
          ) : (
            <div key={sublink.to} className="border-l ml-5">
              <SubLink 
                to={sublink.to} 
                label={sublink.label} 
                onVisit={onVisit} 
              />
            </div>
          )
        ) : null
      )}

      </ExpandableMenuLink>
    );
  };

  const isSpecificPage = location.pathname === '/workflows/create';

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div className="main-layout flex h-screen overflow-hidden">
        <div className={`flex flex-col flex-none ${sidebarClass} px-2 bg-cornflower-blue-500 text-white z-50 ${leftNavExpanded ? "menu_active" : ""}`}>
          <div className="flex items-center justify-between pt-6 pb-5">
            <img src={LogoWhite} alt="Logo" className="w-[44px] h-[38px]" />
            <button onClick={toggleSidebarIcon} className={`${toggleSidebar ? "text-white" : "filter invert relative left-6 hover:bg-steel-blue-600"} hover:bg-cornflower-blue-400 p-2 rounded`}>
              {toggleSidebar ? <IconCollapse /> : <IconExpand />}
            </button>
          </div>

          <div className="overflow-y-auto flex-1 overflow-x-hidden" 
            style={{ 
              maxHeight: 'calc(100vh - 4rem)', 
              scrollbarColor: "#d1e4ff #3794ff",
              scrollbarWidth: "thin",

            }}
          >
            {menuItems.map(renderMenu)}
          </div>

          <div className="border-t border-cornflower-blue-400 my-2">
            <div className="py-2">  
            <MainLink 
              to={messagingClientUrl} 
              label="Agent Workspace" 
              icon={<AgentWorkspace/>} 
              onVisit={() => {}} 
              showTooltip={toggleSidebar}
              toggleSidebar={toggleSidebar}
            />
            </div>
          <div className="border-t border-cornflower-blue-400"></div>
          <ProfileButton 
              picture={profile.picture || "https://example.com/default-profile.jpg"} 
              availabilityColor={availabilityColor} 
              onToggle={() => setShowProfileMenu((prev) => !prev)}
              label={profile.displayName || " "}
              toggleSidebar={toggleSidebar}
            />
          <div className="border-t border-cornflower-blue-400"></div>
            <MainLink 
              to="/help" 
              label="Help" 
              icon={<i className="fa-solid fa-question-circle" />} 
              onVisit={() => setVisitedMenu("Help")} 
              showTooltip={toggleSidebar}
              toggleSidebar={toggleSidebar}
            />
            {/* {showProfileMenu && (
              // <DesktopProfileMenu onClose={() => setShowProfileMenu(false)} />
            )} */}
          </div>
        </div>

        <main className="flex-auto min-w-0 bg-slate-gray-50 shrink">
          <div className={`flex flex-col shrink overflow-auto h-full ${toggleSidebar ? "p-6" : "py-6 pr-6 pl-16"} ${isSpecificPage ? 'bg-white' : 'bg-slate-gray-50'}`}>
            {props.children}
          </div>
        </main>
      </div>
    </div>
  );
}
